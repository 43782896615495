import { useEffect, useState } from "react"

// TObserverTriggersIDs - Допустимые значения для видимости\невидимости элемента

// useObserver - новый хук замене старого, чтобы не тянуть на каждую страницу
// Пример использования: const isVisible = useObserver(["element1", "element2", "element3"]), либо же допускается одно значение в массиве

// При использование, если Вам нужно использовать другой id, то добавьте его к type TObserverTriggersIDs: "app" | "yourAnotherId"
// Options вариативный, если он Вам нужен, то передавайте вторым аргументом
// не повторяйте одинаковые компоненты с разными id

export type TObserverTriggersIDs =
  | "app"
  | "formPkw"
  | "textBottomCalculator"
  | "partners"
  | "questions"
  | "footer"
  | "calculatorCard"
  | "reviewCard"
  | "moreBenefits"
  | "TwoChoice"
  | "calculator"
  | "bannerCta"
  | "bottomCta"
  | "form"

export const useObserver = (
  ids: TObserverTriggersIDs[],
  options?: IntersectionObserverInit,
  initialState: boolean = true
): boolean => {
  const [isVisible, setIsVisible] = useState(initialState)

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const visibleIds = entries
        .filter((entry) => entry.isIntersecting)
        .map((entry) => entry.target.id)

      if (visibleIds.some((id) => ids.includes(id as TObserverTriggersIDs))) {
        setIsVisible(true)
      } else {
        setIsVisible(false)
      }
    }, options)

    ids.forEach((id) => {
      const element = document.getElementById(id)
      if (element) {
        observer.observe(element)
      }
    })

    return () => {
      ids.forEach((id) => {
        const element = document.getElementById(id)
        if (element) {
          observer.unobserve(element)
        }
      })
    }
  }, [ids, options])

  return isVisible
}
