import React, { ReactElement, ReactNode } from "react"

import Header from "@ecom/ui/components/Header/HeaderContainer"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import { useObserver } from "../../../hooks/useObserver"

import { BlackHalvaLogo, RedHalvaWithSovcombank } from "../Logos"
import { RightSection } from "./RightSection/RightSection"

import { pushToDataLayerOnce } from "../../../helpers/pushToDataLayerOnce"

import * as styles from "./newHeader.module.scss"

const options = {
  rootMargin: "0px 0px -50% 0px",
  threshold: 0,
}

export type HeaderProps = {
  children?: React.ReactNode
  buttonText?: string
  withoutHintAndCountDown?: boolean
  onlyGift?: boolean
  showHint?: boolean
  hasCTA?: boolean
  additionalEventInDataLayer?: boolean
  noRightSection?: boolean
  noShadow?: boolean
  countDownTime?: number
  withNav?: boolean
  newYear?: boolean
  hint?: ReactNode
  hintTitle?: ReactElement | string
  background?: string
  withButton?: boolean
  isCountdown?: boolean
  redLogoWithSovcombank?: boolean
  redirectLink?: string
  logoClick?: boolean
}

const classes = {
  logo: styles.logo,
}

export const NewHeader = ({
  children,
  buttonText = "Оформить карту",
  withoutHintAndCountDown = false,
  onlyGift = false,
  showHint = true,
  newYear = false,
  hasCTA = false,
  additionalEventInDataLayer = false,
  noRightSection = false,
  noShadow = true,
  countDownTime = 1800000,
  withNav = false,
  hint,
  hintTitle,
  background,
  withButton = true,
  isCountdown = true,
  redLogoWithSovcombank = false,
  redirectLink = "https://halvacard.ru/",
  logoClick = true,
}: HeaderProps) => {
  const isTab = useMediaQuery("(min-width:600px)")
  const isDesktop = useMediaQuery("(min-width:960px)")
  const isFormFocus = useObserver(["app"], options)
  const checkScreenSizeAndFormFocus = isTab && (isDesktop ? true : !isFormFocus)
  const onLogoClick = () => {
    if (additionalEventInDataLayer) {
      pushToDataLayerOnce("logo-header", { event: "buttonClick", name: "logo-header" })
    }
    window.open(redirectLink, "_blank")
  }

  const logo = redLogoWithSovcombank ? RedHalvaWithSovcombank : BlackHalvaLogo

  const rightSection = (
    <RightSection
      countDownTime={countDownTime}
      withoutHintAndCountDown={withoutHintAndCountDown}
      onlyGift={onlyGift}
      showHint={showHint}
      hasCTA={hasCTA}
      buttonText={buttonText}
      newYear={newYear}
      hint={hint}
      background={background}
      hintTitle={hintTitle}
      isCountdown={isCountdown}
    />
  )

  return (
    <Header
      withNav={withNav}
      withButton={withButton}
      withPhone={false}
      right={countDownTime > 0 && !noRightSection && rightSection}
      onLogoClick={logoClick && onLogoClick}
      noShadow={noShadow}
      Logo={logo}
      sticky={checkScreenSizeAndFormFocus}
      classes={redLogoWithSovcombank && classes}
    >
      {children}
    </Header>
  )
}
